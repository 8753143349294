import React, { useState } from 'react';

const NavBar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div className="navbar">
      <div>
        <a href="/">
          <img src="/Images/SB_Logo.png" className="h-24 w-24" alt="" />
        </a>
      </div>

      {/* Large screen navigation */}
      <div className="hidden md:flex justify-between gap-1">
        <div className="custom-tab">
          <a href="/services">Our Services</a>
        </div>
        <div className="custom-tab">
          <a href="/openreport">Open Report</a>
        </div>
        <div className="custom-tab">
          <a href="/getintouch">Get in touch</a>
        </div>
      </div>

      <div className="md:hidden">
        <button
          className="p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <svg
            className="h-6 w-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>
        {isMenuOpen && (
          <div className="absolute top-20 right-0 w-full bg-gray-100 shadow-lg  p-4">
            <a href="/services" className="block py-2 px-4 hover:bg-gray-200">
              Our Services
            </a>
            <a href="/openreport" className="block py-2 px-4 hover:bg-gray-200">
              Open Report
            </a>
            <a href="/getintouch" className="block py-2 px-4 hover:bg-gray-200">
              Get in touch
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default NavBar;