import React, { useState } from "react";

const OpenReport = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    attachment: null, 
  });
  const [isLoading, setIsLoading] = useState(false); 

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      attachment: e.target.files[0], 
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Start loading

    try {
      const formDataToSend = new FormData();
      formDataToSend.append("name", formData.name);
      formDataToSend.append("email", formData.email);
      formDataToSend.append("message", formData.message);
      if (formData.attachment) {
        formDataToSend.append("attachment", formData.attachment); 
      }

      const response = await fetch(
        "https://sbshipman.com/open_report.php", 
        {
          method: "POST",
          body: formDataToSend,
        }
      );

      if (response.ok) {
        const responseData = await response.text();
        console.log("Response:", responseData);
        alert("Message sent successfully!");
        setFormData({
          name: "",
          email: "",
          message: "",
          attachment: null,
        });
      } else {
        alert("Failed to send message. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again.");
    } finally {
      setIsLoading(false); 
    }
  };

  return (
    <section className="bg-gray-800 py-16">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row items-start justify-between">
          <div className="md:w-1/2 mb-8 md:mb-0">
            <h2 className="text-3xl font-bold text-white mb-4">Open Report</h2>
            <p className="text-gray-300 leading-relaxed">
              If you have any concern or see a breach anywhere in our
              organisation, please let us know. Our team is here to help.
            </p>
          </div>
          <div className="md:w-1/2">
            <form
              className="bg-white rounded-lg shadow-lg p-6"
              onSubmit={handleSubmit}
            >
              <div className="mb-4">
                <label htmlFor="name" className="block font-bold mb-2">
                  Subject
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:ring-2 focus:ring-blue-800"
                  placeholder="Please mention the subject"
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="email" className="block font-bold mb-2">
                  Email <span className="text-sm text-gray-500">(optional)</span>
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:ring-2 focus:ring-blue-800"
                  placeholder="Enter your email"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="message" className="block font-bold mb-2">
                  Details
                </label>
                <textarea
                  id="message"
                  name="message"
                  rows="4"
                  value={formData.message}
                  onChange={handleChange}
                  className="w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:ring-2 focus:ring-blue-800"
                  placeholder="Details of your concern."
                  required
                ></textarea>
              </div>
              <div className="mb-4">
                <label htmlFor="attachment" className="block font-bold mb-2">
                  Attach supporting document{" "}
                  <span className="text-sm text-gray-500">(optional)</span>
                </label>
                <input
                  type="file"
                  id="attachment"
                  name="attachment"
                  onChange={handleFileChange}
                  className="w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:ring-2 focus:ring-blue-800"
                />
              </div>
              <button
                type="submit"
                className="bg-blue-800 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md transition-colors duration-300"
                disabled={isLoading}
              >
                {isLoading ? (
                  <div className="flex items-center justify-center">
                    <svg
                      className="animate-spin h-5 w-5 mr-3 text-white"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291l-2.121 2.122A7.97 7.97 0 014 12H0c0 4.963 4.037 9 9 9v-4a5.977 5.977 0 01-3-1.709z"
                      ></path>
                    </svg>
                    Sending...
                  </div>
                ) : (
                  "Submit"
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OpenReport;
